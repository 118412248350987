import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import line from '../images/line.svg';
import { NEWS_WITH_SELECTED_CATEGORIES_URL,MEDIA_API_URL,NOT_FOUND_IMAGE_URL } from '../constants/api';

const PopularNews = () => {

    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);



    const fetchBlogs = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(NEWS_WITH_SELECTED_CATEGORIES_URL);
          setPosts(response.data);
          console.log(response.data);
         
        } catch (error) {
          console.error("Error fetching blogs:", error);
        } finally {
          setIsLoading(false);
        }
      };

      useEffect(() => {
        fetchBlogs(currentPage);
      }, [currentPage]);
    
  return (
    <>
      <section className="news">
      <div className="container">
                <center><h2 className='mt-4'>Recently Covered</h2></center>
                <div className='row'>
                    {isLoading ? (
                        <p>Loading News...</p>
                    ) : (
                        posts.slice(0, 6).map((post) => (
                            <div className='col-md-4 mb-4' key={post.id}>
                                <div className="box border p-3 rounded bg-light">
                                    <figure>
                                        <span className="Ofisimg">
                                            {/* Set iframe dimensions to fit col-md-4 */}
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: post.content.rendered.replace(
                                                        /<iframe.*?width="800".*?height="450".*?>/,
                                                        match => match.replace('width="800"', 'width="100%"').replace('height="450"', 'height="230"')
                                                    ),
                                                }}
                                            />
                                        </span>
                                    </figure>
                                    <div className="text">
                                        <h6>{post.title.rendered}</h6>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: post.excerpt.rendered,
                                            }}
                                        />
                                    </div>
                                </div>            
                            </div>
                        ))
                    )}
                </div>
            </div>
       
      </section>
    </>
  );
};

export default PopularNews;
