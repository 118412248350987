import React,{useState,useEffect} from 'react'
import {EPISODES} from "../constants/api";
import axios from 'axios';

// import group27 from "../images/Group27.png";
function MaverickPage() {
        const [selectedVideo, setSelectedVideo] = useState(null);
      
        
        const openModal = (videoUrl) => {
            setSelectedVideo(videoUrl);
          };
        
          const closeModal = () => {
            setSelectedVideo(null);
          };

            
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);



    const fetchBlogs = async (page) => {
        try {
          const response = await axios.get(EPISODES);
          setIsLoading(true);
          setPosts(response.data);
          console.log(response.data);
         
        } catch (error) {
          console.error("Error fetching blogs:", error);
        } finally {
          setIsLoading(false);
        }
      };

      useEffect(() => { 
        fetchBlogs(currentPage);
      }, [currentPage]);
        
  return (
    <>
        <section className="passion">
            <div className="container mb-4">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Recently Uploaded</h1>
                        <div className="aboutvideo">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <iframe src="https://www.youtube.com/watch?v=DDbMCR3xjF0&list=PLVoj1GHecmeh389VuMzRC_ISKK3oDcQ1s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullScreen></iframe> */}
                                        <iframe src="https://www.youtube.com/embed/DDbMCR3xjF0?list=PLVoj1GHecmeh389VuMzRC_ISKK3oDcQ1s" 
                                                title="YouTube video player" frameborder="0" 
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                                allowfullscreen>
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-grey pt-2 pb-4'>
              
            <div className="container">
                <h4 className="mt-4">All Episodes</h4>
                <div className="row">
                {posts.map((post, index) => {
                    // Extract YouTube link from the iframe src
                    const iframeSrcMatch = post.content.rendered.match(/<iframe.*?src="(.*?)"/);
                    const youtubeLink = iframeSrcMatch ? iframeSrcMatch[1] : null;

                    return (
                        <div className="col-md-4 mb-4" key={post.id}>
                        <div
                            className="box border p-3 rounded bg-light"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                            if (youtubeLink) {
                                openModal(youtubeLink); // Pass the extracted YouTube link to openModal
                            } else {
                                console.error("YouTube link not found in iframe.");
                            }
                            }}
                        >
                            <figure>
                            <span className="Ofisimg">
                                <div
                                dangerouslySetInnerHTML={{
                                    __html: post.content.rendered.replace(
                                    /<iframe.*?width="800".*?height="450".*?>/,
                                    match =>
                                        match
                                        .replace('width="800"', 'width="100%"')
                                        .replace('height="450"', 'height="auto"')
                                    ),
                                }}
                                style={{ pointerEvents: "none" }} // Ensures iframe doesn't block clicks
                                />
                            </span>
                            </figure>
                            <div className="text">
                            <h6>{post.title.rendered || `Video Title ${index + 1}`}</h6>
                            <p
                                dangerouslySetInnerHTML={{
                                __html: post.excerpt.rendered || 'Choose a workspace solution that suits your business',
                                }}
                            />
                            </div>
                        </div>
                        </div>
                    );
                    })}


                </div>

                {/* Modal */}
                {selectedVideo && (
                    <div
                    className="modal-overlay"
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000,
                    }}
                    onClick={closeModal}
                    >
                    <div
                        className="modal-content"
                        style={{
                        position: "relative",
                        width: "80%",
                        maxWidth: "900px",
                        top:"30px",
                        backgroundColor: "#000",
                        }}
                        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking on the modal content
                    >
                        <iframe
                        src={selectedVideo}
                        title="Enlarged Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        style={{
                            width: "100%",
                            height: "500px",
                            border: "none",
                        }}
                        ></iframe>
                        
                    </div>
                    </div>
                )}
                </div>
            </div>
        </section>
    </>
  )
}

export default MaverickPage
