import React, { useState, useEffect } from "react";
import withMeta from "./withMeta.js";
import axios from "axios";
import Ellipse1 from "../images/blogbanner.jpg";
import Lets from "./Lets";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  POSTS_WITH_EXCLUDED_CATEGORIES_URL,
  NOT_FOUND_IMAGE_URL,
  MEDIA_API_URL,
  CATEGORIES_API_URL,
  USERS_API_URL,
} from "../constants/api";




const Blog = ({ postId }) => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [popularPosts, setPopularPosts] = useState([]);
  const [title, setTitle] = useState("Blogs");
  // const postsPerPage = 5;

  const fetchBlogs = async (page) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://ofissquare.com/wpofissquare/wp-json/wp/v2/posts?_embed&per_page=10&page=${page}&categories_exclude=109,110,9,111`
      );
      setPosts(response.data);
      console.log(response.data);
      const postsData = response.data;
      const sortedPosts = [...postsData].sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      setPopularPosts(sortedPosts.slice(0, 10)); // Take top 10 as popular posts
      setTotalPages(parseInt(response.headers["x-wp-totalpages"], 10));
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    fetchBlogs(currentPage);
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch posts
        // const response = await axios.get(POSTS_WITH_EXCLUDED_CATEGORIES_URL);
        // const postsData = response.data;
        // setPosts(postsData);

        // Fetch categories
        const categoriesResponse = await axios.get(CATEGORIES_API_URL);
        setCategories(categoriesResponse.data);

        // Simulate popular posts logic (e.g., top 5 based on date)
        // const sortedPosts = [...postsData].sort(
        //   (a, b) => new Date(b.date) - new Date(a.date)
        // );
        // setPopularPosts(sortedPosts.slice(0, 5)); // Take top 5 as popular posts
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Frequently Asked Questions for Ofis Square." />
      </Helmet>
      <section className="passion">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Our Blog</h1>
              <img src={Ellipse1} alt="Ellipse1" />
            </div>
          </div>
        </div>
      </section>

      <section className="blogbox">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {isLoading ? (
                <p>Loading blogs...</p>
              ) : (
                posts.map((post) => (
                  <div className="blogdata" key={post.id}>
                    <div className="blogimg">
                      <Link to={`/Blogs/${post.slug}`} className="blogbgremove" target="_blank">
                        <img
                          src={
                            post._embedded?.["wp:featuredmedia"]?.[0]?.source_url ||
                            "https://via.placeholder.com/150"
                          }
                          alt="blogpost"
                        />
                      </Link>
                    </div>
                    <div className="blogtext">
                      <Link to={`/Blogs/${post.slug}`} target="_blank">
                        <ul>
                          <li>
                            On{" "}
                            <span>
                              {new Date(post.date).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              })}
                            </span>
                          </li>
                          <li>
                            By <span>{post._embedded?.author?.[0]?.name || "Unknown"}</span>
                          </li>
                        </ul>
                        <h6>{post.title.rendered}</h6>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: post.excerpt.rendered,
                          }}
                        />
                        <Link className="bloglink" to={`/Blogs/${post.slug}`} >
                          Explore More
                        </Link>
                      </Link>
                    </div>
                  </div>
                ))
              )}
              <div className="pagination-buttons">
                <button
                  className="btn  link_btn mx-2"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  className="btn link_btn"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blogsidebar">
                <h5 className="text-center">Categories</h5>
                <ul>
                  {categories.length > 0 ? (
                    categories.map((category) => (
                      <li key={category.id}>
                        <Link to={`/CategoryBlogs/${category.id}`} target="_blank">
                          {category.name}
                        </Link>
                      </li>
                    ))
                  ) : (
                    <p>No categories available.</p>
                  )}
                </ul>
              </div>


              {/* <div className="blogsidebar">
                                <h5 className="text-center">Popular Post</h5>
                                {posts.map((post, i) => (
                                    <Link to={`/Blogs/${post.slug}`} key={i} className="blogpop">
                                        {post.mediaItem && <img key={post.mediaItem.id} src={post.mediaItem.source_url} alt="blogpost" />}
                                        <span>{categories[post.categories]}</span>
                                        <p dangerouslySetInnerHTML={{ __html: getFirstWords(post.content.rendered, 10) }} />
                                    </Link>
                                ))}
                            </div> */}
              <div className="blogsidebar">
                <h5 className="text-center">Popular Blogs</h5>
                  <ul>
                  {popularPosts.length > 0 ? (
                    popularPosts.map((post) => (
                      <li key={post.id}>
                        <Link to={`/Blogs/${post.slug}`} className="blogpop">
                        {post._embedded?.["wp:featuredmedia"]?.[0]?.media_details?.sizes?.thumbnail?.source_url && (
                              <img
                                  src={post._embedded["wp:featuredmedia"][0].media_details.sizes.thumbnail.source_url}
                                  alt={post._embedded["wp:featuredmedia"][0].alt_text || "Blog post thumbnail"}
                              />
                          )}
                          {post.title.rendered}
                          
                        </Link>
                      </li>
                    ))
                  ) : (
                    <p>No popular blogs available.</p>
                  )}
              </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Lets />
    </>
  );
};

export default withMeta("Blog")(Blog);
