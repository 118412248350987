import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// Import the image files correctly
import Rectangle24 from "../images/image22.jpeg";
import Rectangle25 from "../images/image11.jpeg";
import Rectangle37 from "../images/image21.jpeg";
import Rectangle37a from "../images/Auditorium.png";
import Rectangle52 from "../images/image23.jpeg";
import line from "../images/line.svg";
import DayPass from "./DayPass";
import MeetingRoom from "./MeetingRoom";
import ConferenceRoom from "./ConferenceRoom";
import BoardRoom from "./BoardRoom";
import EventsSpace from "./EventsSpace";

const itemsData = [
  {
    title: "Day pass",
    imgSrc: Rectangle37, // Use the imported variable
    lineImgSrc: line, // Use the imported variable,
    crmLink: {
      scriptSrc: "/DayPass",
    }
  },
  {
    title: "Meeting rooms",
    imgSrc: Rectangle25, // Use the imported variable
    lineImgSrc: line, // Use the imported variable
    crmLink: {
      scriptSrc: "/MeetingRoom",
    }
  },
  {
    title: "Conference rooms",
    imgSrc: Rectangle24, // Use the imported variable
    lineImgSrc: line, // Use the imported variable
    crmLink: {
      scriptSrc: "/ConferenceRoom",
    }
  },
  {
    title: "Board rooms",
    imgSrc: Rectangle52, // Use the imported variable
    lineImgSrc: line, // Use the imported variable
    crmLink: {
      scriptSrc: "/BoardRoom",
    }
  },
  {
    title: "Events Spaces",
    imgSrc: Rectangle37a, // Use the imported variable
    lineImgSrc: line, // Use the imported variable
    crmLink: {
      scriptSrc: "/EventsSpace",
    }
  },

  // Add more data objects for additional items
];
const options = {
  items: 3,
  nav: true,
  rewind: true,
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 3
    },
    1000: {
      items: 3
    }
  }
};

const Demandbook = () => {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const handleShow = (item) => {
    setSelectedItem(item);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  //window.scrollTo(0, 0);
  return (
    <>
      <section className="dbook">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>On Demand Bookings</h2>
              {/* <div className="owl-carousel" id="bookings"> */}
              <OwlCarousel className="owl-carousel owl-loaded owl-drag" {...options} autoplay margin={30}>
                {itemsData.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="box">
                      <figure>
                        <span>
                          <img src={item.imgSrc} alt={item.title} />
                        </span>
                      </figure>
                      <a onClick={() => handleShow(item)}>
                        <h5>{item.title}</h5>
                        <span>
                          <img src={item.lineImgSrc} alt="Line" />
                        </span>
                      </a>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
              {selectedItem && (
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header>
                    <Modal.Title>{selectedItem ? selectedItem.title : "Modal Title"}</Modal.Title>
                    <Button variant="secondary" onClick={handleClose}>X</Button>
                  </Modal.Header>
                  <Modal.Body>
                  {selectedItem.crmLink.scriptSrc === "/DayPass" && (
                      <DayPass isOpen={show} onModalClose={handleClose} />
                    )}
                  {selectedItem.crmLink.scriptSrc === "/MeetingRoom" && (
                      <MeetingRoom isOpen={show} onModalClose={handleClose} />
                    )}
                    {selectedItem.crmLink.scriptSrc === "/ConferenceRoom" && (
                      <ConferenceRoom isOpen={show} onModalClose={handleClose} />
                    )}
                    {selectedItem.crmLink.scriptSrc === "/BoardRoom" && (
                      <BoardRoom isOpen={show} onModalClose={handleClose} />
                    )}
                    {selectedItem.crmLink.scriptSrc === "/EventsSpace" && (
                      <EventsSpace isOpen={show} onModalClose={handleClose} />
                    )}
                  </Modal.Body>
                </Modal>
              )}
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Demandbook;
