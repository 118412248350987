import React,{useState} from "react";
import image1 from "../images/sharadha21.png";
import image2 from "../images/sharadha_text.png";
// import image3 from "../images/content.png";
import image4 from "../images/meet.png";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
const Wosquare = () => {
  // const [activeTab, setActiveTab] = useState(0);
  // const handleTabClick = (index) => {
  //   setActiveTab(index);
  // };
  return (
    <>
      {/* <section className="wosquare">
        <div className="container watermark">
          <div className="row">
            <div className="col-sm-5">
                <img src={image4} className="image4"/>
                <img src={image2} className="image2"/> 
            </div>
            <div className="col-sm-2">
                <img src={image1} className="image1"/>
            </div>
            <div className="col-sm-5">
              <p className="content">We are thrilled to welcome Shraddha Kapoor as the face of Ofis Square! Known for her versatility, creativity, and inspiring journey, Shraddha perfectly embodies the spirit of innovation and collaboration that drives our brand. With her vibrant personality and dynamic outlook, Shraddha aligns seamlessly with our vision to redefine workspaces and foster future-ready solutions.

                <br/><br/>Together, we aim to inspire businesses and professionals to think beyond traditional offices and embrace spaces that fuel growth and success.</p>
            </div>
          </div>
        </div>
      </section> */}

<section className="wosquare">
  <div className="container watermark">
    <div className="row">
 
      <div className="col-lg-5 col-md-6 col-sm-12 text-center">
        <img src={image4} className="image4" alt="Meet Our Ambassador" />
        <img src={image2} className="image2" alt="Shraddha Kapoor Text" />
      </div>
   
      <div className="col-lg-2 col-md-12 text-center">
        <img src={image1} className="image1" alt="Shraddha Kapoor Image" />
      </div>
  
      <div className="col-lg-5 col-md-12 text-center">
        <p className="content">
          We are thrilled to welcome Shraddha Kapoor as the face of Ofis Square! Known for her versatility, creativity, and inspiring journey, Shraddha perfectly embodies the spirit of innovation and collaboration that drives our brand. With her vibrant personality and dynamic outlook, Shraddha aligns seamlessly with our vision to redefine workspaces and foster future-ready solutions.
          <br /><br />
          Together, we aim to inspire businesses and professionals to think beyond traditional offices and embrace spaces that fuel growth and success.
        </p>
      </div>
    </div>
  </div>
</section>

    </>
  );
};

export default Wosquare;
