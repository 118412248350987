import React, { useState, useEffect } from "react";
import group27 from "../images/Group27.png";
import Lets from "./Lets";
import { Link } from "react-router-dom";
import {POPULAR_NEWS_WITH_SELECTED_CATEGORIES_URL} from "../constants/api";

import axios from "axios";

function NewsPage() {
   
    const [posts, setPosts] = useState([]);
    const [popular, setPopular] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);



    const fetchBlogs = async (page) => {
        const api = `https://ofissquare.com/wpofissquare/wp-json/wp/v2/posts?_embed&per_page=10&${page}&categories=109`;
        setIsLoading(true);
        try {
          const response = await axios.get(api);
          setPosts(response.data);
          console.log(response.data);
         
        } catch (error) {
          console.error("Error fetching blogs:", error);
        } finally {
          setIsLoading(false);
        }
      };

      useEffect(() => {
        const fetchBlogs1 = async () => {
            setIsLoading(true);
            try {
              const response = await axios.get(POPULAR_NEWS_WITH_SELECTED_CATEGORIES_URL);
              setPopular(response.data);
              console.log(response.data);
             
            } catch (error) {
              console.error("Error fetching blogs:", error);
            } finally {
              setIsLoading(false);
            }
          };
        fetchBlogs(currentPage);
        fetchBlogs1();
      }, [currentPage]);
    
  return (
    <>
        <section className="passion">
            <div className="container mb-4">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Press & Media Coverage</h1>
                        <div className="aboutvideo">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                    <iframe src="https://www.youtube.com/embed/DDbMCR3xjF0" 
                                            title="YouTube video player" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowfullscreen>
                                    </iframe>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-grey pt-2 pb-4'>
            <div className="container">
                <h4 className='mt-4'>Recently Covered</h4>
                <div className='row'>
                    {isLoading ? (
                        <p>Loading News...</p>
                    ) : (
                        posts.map((post) => (
                            <div className='col-md-4 mb-4' key={post.id}>
                                <div className="box border p-3 rounded bg-light">
                                    <figure>
                                        <span className="Ofisimg">
                                            {/* Set iframe dimensions to fit col-md-4 */}
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: post.content.rendered.replace(
                                                        /<iframe.*?width="800".*?height="450".*?>/,
                                                        match => match.replace('width="800"', 'width="100%"').replace('height="450"', 'height="auto"')
                                                    ),
                                                }}
                                            />
                                        </span>
                                    </figure>
                                    <div className="text">
                                        <h6>{post.title.rendered}</h6>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: post.excerpt.rendered,
                                            }}
                                        />
                                    </div>
                                </div>            
                            </div>
                        ))
                    )}
                </div>
            </div>

            <div className="container">
                <h4 className='mt-4'>Latest Events</h4>
                <div className='row'>
                    {isLoading ? (
                        <p>Loading News...</p>
                    ) : (
                        popular.slice(0,3).map((post) => (
                            <div className='col-md-4 mb-4' key={post.id}>
                                <div className="box border p-3 rounded bg-light">
                                    <figure>
                                        <span className="Ofisimg">
                                            {/* Set iframe dimensions to fit col-md-4 */}
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: post.content.rendered.replace(
                                                        /<iframe.*?width="800".*?height="450".*?>/,
                                                        match => match.replace('width="800"', 'width="100%"').replace('height="450"', 'height="auto"')
                                                    ),
                                                }}
                                            />
                                        </span>
                                    </figure>
                                    <div className="text">
                                        <h6>{post.title.rendered}</h6>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: post.excerpt.rendered,
                                            }}
                                        />
                                    </div>
                                </div>            
                            </div>
                        ))
                    )}
                </div>
            </div>
            </div>
        </section>
    </>
  )
}

export default NewsPage
