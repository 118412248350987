import React from "react";
import { Helmet } from "react-helmet";
import metaTags from "../metaTag.json";
const withMeta = (pageKey) =>   (WrappedComponent) =>{
  return (props) => {
    const meta = metaTags[pageKey] || {};
    //console.log(`Page Key: ${pageKey}`);
    //console.log(`Title: ${meta.title || "Default Title"}`);
    console.log(`Description: ${meta.description || "Default Description"}`);
    return (
      <>
        <Helmet>
          <title>
            {meta.title ||
              "Ofis Square - Premium Coworking and Managed Office Spaces"}
          </title>
          <meta
            name="description"
            content={
              meta.description ||
              "Ofis Square provides premium coworking & fully managed offices featuring high-speed Wifi,private offices,conference rooms,phone-booths. & more Book Now!"
            }
          />
        </Helmet>
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default withMeta;
