import React, { useState, useEffect } from "react";
import withMeta from "./withMeta.js";
import axios from "axios";
import Workspaces from "./Workspaces";
import Upcomingpg from "./Upcomingpg";
//import LetsCoworking from './LetsCoworking';
import { Link } from "react-router-dom";
import { MEDIA_API_URL, OFFICE_LOCATION_API_URL } from "../constants/api";

const CoworkingspacesNoida = () => {
  const [data, setData] = useState([]);
  const [dataSelect, setSelectData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedBusinessSuit, setSelectedBusinessSuit] = useState("");
  //const [searchError, setSearchError] = useState('');
  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const handleChangeBusinessSuit = (e) => {
    setSelectedBusinessSuit(e.target.value);
  };
  const handleButtonClick = async (e) => {
    e.preventDefault();
    setData([]);
    //console.log('Selected selectedValue:', selectedValue);
    //console.log('Selected Business Suit:', selectedBusinessSuit);
    if (selectedValue || selectedBusinessSuit) {
      let imageUrl;
      let workspace_ssolution = [];
      try {
        const response = await axios.get(
          `${OFFICE_LOCATION_API_URL}/${selectedValue}?business=${selectedBusinessSuit}`,
          {
            params: {
              taxonomy: "category",
              // Include additional parameters based on selectedValue and selectedBusinessSuit
            },
          }
        );
        if (Array.isArray(response.data)) {
          for (const item of response.data) {
            if (item.acf && item.acf.workspace_ssolution) {
              imageUrl = item.acf ? await getImageUrl(item.acf.image) : null;
              workspace_ssolution = item.acf.workspace_ssolution.map((item) => {
                return item;
              });
              const locationsData = {
                ...(item.acf || {}),
                imageUrl,
                workspace_ssolution,
              };
              const locationIndex = data.findIndex(
                (prevLocation) => prevLocation === locationsData
              );
              // If location exists, update it; otherwise, add it to the state
              if (locationIndex !== -1) {
                setData((prevData) => {
                  const newData = [...prevData];
                  newData[locationIndex] = locationsData;
                  return newData;
                });
              } else {
                setData((prevData) => [...prevData, locationsData]);
              }
            } else {
              console.log("error getting");
            }
          }
        }
      } catch (error) {
        console.error("Error Fetching Data:", error);
      }
    } else {
      // setSearchError('Please select both options before searching.');
      let imageUrl;
      let workspace_ssolution = [];
      try {
        const response = await axios.get(
          `${OFFICE_LOCATION_API_URL}/${selectedValue}?business=${selectedBusinessSuit}`,
          {
            params: {
              taxonomy: "category",
              // Include additional parameters based on selectedValue and selectedBusinessSuit
            },
          }
        );
        if (Array.isArray(response.data)) {
          for (const item of response.data) {
            if (item.acf && item.acf.workspace_ssolution) {
              imageUrl = item.acf ? await getImageUrl(item.acf.image) : null;
              workspace_ssolution = item.acf.workspace_ssolution.map(
                (item) => item
              );
              const locationsData = {
                ...(item.acf || {}),
                imageUrl,
                workspace_ssolution,
              };
              const locationIndex = data.findIndex(
                (prevLocation) => prevLocation === locationsData
              );
              // If location exists, update it; otherwise, add it to the state
              if (locationIndex !== -1) {
                setData((prevData) => {
                  const newData = [...prevData];
                  newData[locationIndex] = locationsData;
                  return newData;
                });
              } else {
                setData((prevData) => [...prevData, locationsData]);
              }
            } else {
              console.log("error getting");
            }
          }
        }
      } catch (error) {
        console.error("Error Fetching Data:", error);
      }
    }
    if (selectedValue) {
      try {
        const response = await axios.get(
          `${OFFICE_LOCATION_API_URL}/${selectedValue}?business=${selectedBusinessSuit}`,
          {
            params: {
              taxonomy: "category",
              // Include additional parameters based on selectedValue and selectedBusinessSuit
            },
          }
        );
        console.log("third" + response);
        const imageUrl = response.data.acf
          ? await getImageUrl(response.data.acf.image)
          : null;
        // const workspace_ssolution = response.data && response.data.acf.workspace_ssolution ? response.data.acf.workspace_ssolution : null;
        // console.log(workspace_ssolution);
        const locationsData = {
          ...(response.data.acf || {}),
          imageUrl,
          //workspace_ssolution: response.data.acf ? response.data.acf.workspace_ssolution : null,
        };
        const locationIndex = data.findIndex(
          (prevLocation) => prevLocation.id === locationsData.id
        );
        // If location exists, update it, otherwise add it to the state
        if (locationIndex !== -1) {
          setData((prevData) => {
            const newData = [...prevData];
            newData[locationIndex] = locationsData;
            return newData;
          });
        } else {
          setData((prevData) => [...prevData, locationsData]);
        }
      } catch (error) {
        console.error("Error Fetching Data:", error);
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(OFFICE_LOCATION_API_URL, {
          params: {
            taxonomy: "category",
            categories: 10,
          },
        });
        const locationsData = await Promise.all(
          response.data.map(async (post) => {
            const imageUrl = await getImageUrl(post.acf.image);
            const workspace_ssolution = post.acf.workspace_ssolution || {};
            return {
              ...post.acf,
              imageUrl,
              workspace_ssolution,
            };
          })
        );
        //console.log(locationsData);
        //const locationsData = response.data.map(post => post.acf);
        setData(locationsData);
      } catch (error) {
        console.error("Error Fetching Data:", error);
      }
    };
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  useEffect(() => {
    const fetchDataSelect = async () => {
      try {
        const response = await axios.get(OFFICE_LOCATION_API_URL, {
          params: {
            taxonomy: "category",
          },
        });
        const locationsData = await Promise.all(
          response.data.map(async (post) => {
            const imageUrl = await getImageUrl(post.acf.image);
            const workspace_ssolution = post.acf.workspace_ssolution || {};
            return {
              id: post.id,
              ...post.acf,
              imageUrl,
              workspace_ssolution,
            };
          })
        );
        //const locationsData = response.data.map(post => post.acf);
        setSelectData(locationsData);
      } catch (error) {
        console.error("Error Fetching Data:", error);
      }
    };
    fetchDataSelect();
  }, []);
  const getImageUrl = async (attachmentId) => {
    try {
      const imageResponse = await axios.get(`${MEDIA_API_URL}/${attachmentId}`);
      return imageResponse.data.source_url;
    } catch (error) {
      console.error(
        `Error fetching image URL for attachment ID ${attachmentId}:`,
        error
      );
      return null;
    }
  };
  //withMeta("CoworkingspacesNoida");
  return (
    <>
      <div className="banner" id="bannerCoworkingNoida">
        <div className="container">
          <div className="row">
            <h1>
              Coworking Spaces <span> in Noida</span>
            </h1>
          </div>
        </div>
      </div>
      <section className="osyc">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Ofis Square in Noida</h2>
              <p>Enhance your work in Noida's premier coworking spaces.</p>
              <p>
                Are you fed up with boring, uninspiring office spaces? It's time
                to level up your work environment. Discover Noida's most coveted
                coworking destinations - spaces designed to fuel productivity,
                creativity, and success for today's innovative businesses.
              </p>
              <p>
                Picture walking into a sleek, modern workspace filled with
                sunlight, ergonomic furnishings, and the latest technologies.
                Now envision being surrounded by a vibrant community of
                like-minded professionals, with fruitful collaborations and
                meaningful connections happening spontaneously. This is the
                essence of our coworking spaces.
              </p>
              <p>
                Whether you're a fledgling startup or an established enterprise,
                we provide flexible, customizable plans to match your needs.
                Desks, private offices, meeting rooms & board rooms, event
                spaces - we have it all in Noida's most thriving centres.
              </p>
              <p>
                Don't settle for lackluster offices that stifle inspiration.
                Join our coworking community today to unlock your potential in
                an environment crafted for you. Discover what true passion for
                work really feels like. Your breakthrough awaits at our
                coworking spaces!
              </p>

              <ul>
                <li>
                  <div className="box">
                    <h5 className="">
                      <span></span> 2
                    </h5>
                    <h5 className="opacity">Centers</h5>
                    {/* <p>
                              Sector 62, Noida and Sector 3, Noida
                              </p> */}
                  </div>
                </li>
                <li>
                  <div className="box">
                    <h5 className="">
                      <span></span> 1.6Lac+ sqft
                    </h5>
                    <h5 className="opacity">Area</h5>
                    {/* <p>
                                 Lorem ipsum dolor sit amet, conser adipiscing elit, sed do
                                 eiusmod tdr incididunt
                              </p> */}
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.075217391884!2d77.37089337483582!3d28.62750768431012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce53972341851%3A0x25e800a3d3331592!2sOfis%20Square%20%7C%20Coworking%20in%20Sector-62%20Noida!5e0!3m2!1sen!2sin!4v1691820672303!5m2!1sen!2sin"
                allowfullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps Embed - Ofis Square, Sector-62 Noida"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="osc news">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2>Ofis Square Noida</h2>
              <form className="search">
                {/* <select>
                           <option>Find a coworking space near me</option>
                           <option>Find a coworking space near me</option>
                        </select> */}
                <select value={selectedValue} onChange={handleChange}>
                  <option value="">Please Select</option>
                  {dataSelect.map((location, index) => (
                    <option value={location.id} key={index}>
                      {location.title}
                    </option>
                  ))}

                  {/* <option value="Coworking space in Gurugram">Coworking space in Gurugram</option>
                           <option value="Coworking space in sector 62">Coworking space in sector 62</option>
                           <option value="Coworking space in MG road">Coworking space in MG road </option>
                           <option value="Coworking space in Sohna Road">Coworking space in Sohna Road </option>
                           <option value="Coworking space in Noida sector 3">Coworking space in Noida sector 3 </option> */}
                </select>
                <select
                  value={selectedBusinessSuit}
                  onChange={handleChangeBusinessSuit}
                >
                  {data
                    .reduce((uniqueBusinessSuits, location) => {
                      location.workspace_ssolution &&
                        Object.values(location.workspace_ssolution).forEach(
                          (value) => {
                            const businessSuitsValue =
                              typeof value === "object"
                                ? value.business_suits
                                : value;
                            if (
                              !uniqueBusinessSuits.includes(businessSuitsValue)
                            ) {
                              uniqueBusinessSuits.push(businessSuitsValue);
                            }
                          }
                        );
                      return uniqueBusinessSuits;
                    }, [])
                    .map((businessSuitsValue, index) => (
                      <option key={index} value={businessSuitsValue}>
                        {businessSuitsValue}
                      </option>
                    ))}
                </select>
                {/* <Link to={selectedValue === 'option1' ? '/OfisSquareInNoida-1.6Lac' :
                           selectedValue === 'option2' ? '/OfisSquareInGurugram-47K' :
                              selectedValue === 'option3' ? '/Sector62Noida' :
                                 selectedValue === 'option4' ? '/MgRoadGurugram' :
                                    selectedValue === 'option5' ? '/SonaRoadGurugram' :
                                       selectedValue === 'option6' ? '/NoidaSector3' :
                                          '/'}>
                           
                        </Link> */}
                <button onClick={handleButtonClick}>Search</button>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <ul>
                {data.map((location, index) => (
                  <li key={index}>
                    <Link to={location.page_link}>
                      <div className="box">
                        <figure>
                          <span className="Ofisimg">
                            <img
                              src={location.imageUrl}
                              alt={`Office Location ${index + 1}`}
                            />
                          </span>
                        </figure>
                        <div className="text">
                          <h5 className="text-center">{location.title}</h5>
                          <ul className="ofis-box">
                            <li>
                              <p>
                                <span>
                                  <img
                                    src="images/Vector1 (4).png"
                                    alt="vector1"
                                    height="15"
                                    width="15"
                                  />
                                </span>{" "}
                                {location.desk_number} Desks
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  <img
                                    src="images/Vector2 (3).png"
                                    height="20"
                                    width="20"
                                    alt="vector2"
                                  />
                                </span>
                                {location.square_feet} SqFt
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  <img
                                    src="images/metro-station.png"
                                    height="20"
                                    width="20"
                                    alt="metro"
                                  />
                                </span>
                                {location.location_center}
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>
                                  <img
                                    src="images/Vector4 (1).png"
                                    height="20"
                                    width="20"
                                    alt="vector4"
                                  />
                                </span>
                                {location.distance_from_airport} KM from airport
                              </p>
                            </li>
                          </ul>
                          <p className="osctext">
                            Choose a workspace solution that suits your business
                          </p>
                          <ul className="osclastcol">
                            {location.workspace_ssolution &&
                              Object.keys(location.workspace_ssolution).map(
                                (key, index) => (
                                  <li key={index}>
                                    {typeof location.workspace_ssolution[
                                      key
                                    ] === "object"
                                      ? location.workspace_ssolution[key]
                                          .business_suits
                                      : location.workspace_ssolution[key]}
                                  </li>
                                )
                              )}
                          </ul>
                          <Link
                            to={location.page_link}
                            className="link text-center"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Workspaces />
      <Upcomingpg />
      {/* <LetsCoworking /> */}
    </>
  );
};

export default withMeta("CoworkingspacesNoida")(CoworkingspacesNoida);
