import React, { useState, useEffect } from 'react'
import withMeta from "./withMeta.js"
import axios from 'axios';
import Rectanglebelowtestimonial from "../images/heos.jpg";
import Rectanglebelowtestimonialev1 from "../images/ev-1.png";
import Rectanglebelowtestimonialev2 from "../images/ev-2.png";
import Rectanglebelowtestimonialev3 from "../images/ev-3.png";
import Rectanglebelowtestimonialev4 from "../images/ev-4.png";
import Rectanglebelowtestimonialev5 from "../images/ev-5.png";
import Rectanglebelowtestimonialev6 from "../images/ev-6.png";
import Rectanglebelowtestimonialev7 from "../images/ev-7.png";
import Rectanglebelowtestimonialev8 from "../images/ev-8.png";
import Rectanglebelowtestimonialevevcal from "../images/ev-cal.png";
//import Rectanglebelowtestimonialevnev1 from "../images/n-ev.png";
//import Rectanglebelowtestimonialevnev2 from "../images/n-ev-2.png";
// import Rectanglebelowtestimonialevnev3 from "../images/n-ev-3.png";
// import Rectanglebelowtestimonialevnev4 from "../images/n-ev-4.png";
// import Rectanglebelowtestimonialevnev5 from "../images/n-ev-5.png";
//import Lets from './Lets';
import EventsSpace from './EventsSpace';
import { Link } from 'react-router-dom';
import { CATEGORIES_API_URL,MEDIA_API_URL,NOT_FOUND_IMAGE_URL,POSTS_CATEGORIES_API_URL } from '../constants/api';
const EventSpaces = () => {
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${POSTS_CATEGORIES_API_URL}=8`);
                const champData = Object.values(response.data);
                const postsWithMedia = await Promise.all(
                    champData.map(async (employee) => {
                        const media_id = employee.featured_media;
                        if (media_id === 0) {
                            return { ...employee, mediaItem: { source_url: NOT_FOUND_IMAGE_URL } };
                        } else {
                            const featuredResponse = await axios.get(`${MEDIA_API_URL}/${media_id}`);
                            const mediaItem = featuredResponse.data;
                            return { ...employee, mediaItem };
                        }
                    })
                );
                setPosts(postsWithMedia);

                const categoriesResponse = await axios.get(CATEGORIES_API_URL);
                //console.log(categoriesResponse.data);
                const categoriesData = categoriesResponse.data.reduce((acc, category) => {
                    acc[category.id] = category.name;
                    return acc;
                }, {});
                setCategories(categoriesData);

            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };
        window.scrollTo(0, 0);
        fetchPosts();
    }, []);
    const itemsData = [
        {
            img: Rectanglebelowtestimonialev1,
            heading: 'Event Planning Assistance',
        },
        {
            img: Rectanglebelowtestimonialev2,
            heading: 'Thoughtful Coworking Space Design',
        },
        {
            img: Rectanglebelowtestimonialev3,
            heading: 'Parking Validation',
        },
        {
            img: Rectanglebelowtestimonialev4,
            heading: 'Premium Hospitality',
        },
        {
            img: Rectanglebelowtestimonialev5,
            heading: 'Enterprise-Grade Secure WiFi',
        },
        {
            img: Rectanglebelowtestimonialev6,
            heading: 'Access to Lounges & Breakout Areas',
        },
        {
            img: Rectanglebelowtestimonialev7,
            heading: 'Printing & Copying Services',
        },
        {
            img: Rectanglebelowtestimonialev8,
            heading: 'Convenient Location in the Heart of the City',
        },
    ]
    const getFirstWords = (content, wordCount) => {
        const strippedContent = content.replace(/<[^>]*>/g, ''); // Remove HTML tags
        const words = strippedContent.split(/\s+/); // Split the text into words
        const firstWords = words.slice(0, wordCount).join(' '); // Take the first N words and join them
        return `${firstWords}...`; // Add ellipsis or modify as needed
    };
    return (
        <>
            <div className="heos">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Host Events At <span className="orange">Ofis Square</span> Experience Our Coworking Space</h2>
                            <div className="box">
                                <p className="black">Book our premium coworking spaces for your next business event in the heart of the city. Product Launches, Workshops, Networking Sessions, Press Conferences - host impactful events in our thoughtfully designed spaces with seamless technology, to help your team connect and collaborate at their best.</p>
                                <p className="black">Our coworking space offers flexible options to meet your needs, whether you need space for a small meeting or a large event. Take advantage of our community amenities like lounge areas, cafe, printing and more to make your event a success.
                                    <span className="space"></span> Ofis Square will transform your next event experience.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={Rectanglebelowtestimonial} alt='Rectanglebelowtestimonial' />
                        </div>
                    </div>
                </div>
            </div>
            <section className="baes">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2><span className="orange">Book</span> An Event Space</h2>
                            <p className="black">When you host events at Ofis Square, you get:</p>
                            <ul className="text-center">
                                {itemsData.map((item, index) => (
                                    <li key={index}>
                                        <span><img src={item.img} alt={`Icon ${index + 901}`} /></span>
                                        <p>{item.heading}</p>
                                    </li>
                                ))}
                            </ul>
                            <p className="black">Upgrade your next event with Ofis Square's coworking space, which is customised to your specific requirements. Our team will ensure you have the best experience when hosting an event with us.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ose">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <img src={Rectanglebelowtestimonialevevcal} alt={Rectanglebelowtestimonialevevcal} className="w-auto mb-2" />
                            <h4><span className="orange">Ofis Square</span> Events</h4>
                            {posts.length > 0 && posts[0].mediaItem && (
                                <React.Fragment>
                                    <Link to={`/Blogs/${posts[0].slug}`}>
                                        <h5 className="mb-0 mt-5">{posts[0].title.rendered}</h5>
                                        <p dangerouslySetInnerHTML={{ __html: getFirstWords(posts[0].content.rendered, 10) }} />
                                        <img src={posts[0].mediaItem.source_url} alt={posts[0].title.rendered} />
                                    </Link>
                                </React.Fragment>
                            )}
                            {posts.length > 1 && posts[1].mediaItem && (
                                <React.Fragment>
                                    <Link to={`/Blogs/${posts[1].slug}`}>
                                        <h5 className="mb-0 mt-5">{posts[1].title.rendered}</h5>
                                        <p dangerouslySetInnerHTML={{ __html: getFirstWords(posts[1].content.rendered, 10) }} />
                                    </Link>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="col-md-3">
                            {posts.length > 2 && posts[2].mediaItem && (
                                <React.Fragment>
                                    <Link to={`/Blogs/${posts[2].slug}`}>
                                        <img src={posts[2].mediaItem.source_url} alt={posts[2].title.rendered} />
                                        <h5 className="mb-0 mt-5">{posts[2].title.rendered}</h5>
                                        <p dangerouslySetInnerHTML={{ __html: getFirstWords(posts[2].content.rendered, 10) }} />
                                    </Link>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="col-md-6">
                            {posts.slice(3).map((post, index) => (
                                post && post.mediaItem && (
                                    <div className={`box mt-4 mb-4 ${index % 2 === 0 ? 'order-md-2' : ''}`} key={index}>
                                        <Link to={`/Blogs/${post.slug}`} className={`images ${index % 2 === 0 ? 'order-md-2' : ''}`}>
                                            <img src={post.mediaItem.source_url} alt={post.title.rendered} />
                                        </Link>
                                        <div className={`text ${index % 2 === 0 ? 'order-md-1' : ''}`}>
                                            <Link to={`/Blogs/${post.slug}`}>
                                                <h5 className="single-line">{post.title.rendered}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: getFirstWords(post.content.rendered, 10) }} />
                                            </Link>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <section className="eventspaces">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            {/* <h2 className="white">Event Spaces</h2>
                            <p className="white">Please complete this form to create an account, receive email updates and much more.</p> */}
                            <EventsSpace />
                            <div className="fotm"></div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Lets /> */}
        </>
    )
}

export default withMeta("EventSpaces")(EventSpaces)