import React from "react";

const FaqSohnaRoad = () => {
  return (
    <>
      <div className="Frequently">
        <div className="container">
          <div className="row">
            <h2 className="text-center">Frequently Asked Questions</h2>
            <h5>Memberships and Packages</h5>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h4 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Who should I reach out to in case of any questions regarding
                    my booking?
                  </button>
                </h4>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    For any questions on your bookings, payments or orders, you
                    can simply write to the dedicated Ofis Square specific
                    support team at hello@ofissquare.com.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Can I bring guests?
                  </button>
                </h4>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Guests are only permitted for those booking conference rooms
                    (up to the quantity of the respective conference room).
                  </div>
                </div>
              </div>
              <h5 className="mt-5">General</h5>
              <div className="accordion-item">
                <h4 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    What Does OFIS SQUARE Offer?
                  </button>
                </h4>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Ofis Square provides convenient and flexible office space
                    solutions for enterprises to minimize their overhead
                    expenses. Ofis Square offers a holistic experience to all
                    its member companies by offering modern cost-effective
                    solutions, state-of-the-art infrastructure with a
                    tech-enabled environment. Our managed office space solutions
                    include:
                    <br />
                    • Dedicated fully-equipped workstations for your employees.
                    <br />
                    • State-of-the-art conference rooms & Boardroom for
                    meetings, brainstorming sessions and corporate reviews.
                    <br />
                    • Cherish your work breaks at Ofis Square in Sohna Road with
                    a serene terrace garden.
                    <br />
                    • Efficient and fully equipped virtual offices to help
                    employees work remotely.
                    <br />
                    • Vibrant and innovative office designs with configurable
                    office spaces.
                    <br />
                    • Additional ancillary services for your business.
                    <br />
                    • Locker, courier services, Breakout zone, cafeteria and
                    pantry are some of the many services provided.
                    <br />
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="headingfour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefour"
                    aria-expanded="false"
                    aria-controls="collapsefour"
                  >
                    So, what are the Pricing Plans?
                  </button>
                </h4>
                <div
                  id="collapsefour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    We have convenient and affordable pricing plans to suit your
                    budgets with no hidden costs. Unlike conventional workplace
                    providers, Ofis Square gives businesses the option to choose
                    flexible lease tenures. Our flexible lease and pricing
                    patterns prove to be exceptionally cost-effective for
                    businesses and help them eradicate their overhead expenses.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="headingFive">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    What are OFIS SQUARE operating hours?
                  </button>
                </h4>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Our business hours are between 10 a.m. to 7 p.m. from Monday
                    to Saturday.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="headingSix">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Do I get access to all OFIS SQUARE locations?
                  </button>
                </h4>
                <div
                  id="collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    If you are a member at one centre, you get access to all our
                    centres. Furthermore, the OFIS SQUARE app allows you not
                    only to book meeting rooms at other locations but also get
                    access to printers, Wi-Fi, etc.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h4 className="accordion-header" id="headingSeven">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Can I use this address as my company's registered address?
                  </button>
                </h4>
                <div
                  id="collapseSeven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    As a Day Pass User, you will not be able to use the centre’s
                    address as your registered address. However, if you upgrade
                    to a private office along with an additional applicable
                    charge as per the centre, you will be able to use the
                    address as your company’s registered address. For more
                    information, you can reach out to the Centre Team.
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="accordion" id="accordionExample">
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqSohnaRoad;
